<template>
    <div class="scroll-container">
      <div class="scroll-content">
      <div class="form-group">
        <div class="row">
          <div class="col-4">
            <label for="iStamp">Month</label>
            <input type="month" @input="genReport()" name="idate" id="idate" class="form-control" v-model="shroffObj.idate" />
          </div>
        </div>
      </div>
    
      <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    
      <div class="invoice-contain">
        <div class="invoice-form-contain">
          <form id="invoiceForm" novalidate autocomplete="off">
            <div id="PrintContent"><div class="hidden-content">This is hidden content.</div>
              <h5>LG4</h5>
              <div class="form-group">
                <div class="heading">
                  <h4 style="text-align: center; padding-top: 0px; padding-bottom: 0px;">
                    Monthly Income Report | මාසික ලැබීම් විශ්ලේෂණය<br>
                    {{ sabhadetail.sb_name_en }} | {{ sabhadetail.sb_name_sin }}<br>
                    {{ sabhadetail.sb_address }}
                  </h4>
                </div>
                <h6 style="padding-top: 0px; padding-bottom: 0px;">
                  <label for="iDate">
                    Year and Month | වර්ෂය හා මාසය: {{ this.shroffObj.idate }}
                  </label>
                </h6>
                <table style="width: 98%; text-align: center; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse;">
                  <thead>
                    <tr>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Date <br>දිනය</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Cash(Rs.) <br>මුදල්(රු.)</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Cheque(Rs.) <br>චෙක්(රු.)</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total(Rs.) <br>එකතුව(රු.)</th>
                      <th style="writing-mode: vertical-rl; transform: rotate(180deg); text-align: left; border-width: 1px; border-style: solid; border-color: #130f40; width: 7%; white-space: normal; overflow: hidden;" v-for="head in headsar" :key="head.id">
                        
                          {{ head.sb_rate_head }} - {{ head.sb_rate_head_name }}
                       
                      </th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">STAMP(Rs.) <br>මුද්දර බද්ද(රු.)</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">VAT(Rs.) <br>වැට්(රු.)</th>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">DISCOUNT(Rs.) <br>වට්ටම්(රු.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- v-for="(date, index) in dates" :key="index" -->
                    <tr v-for="(r, index) in datesofmonth" :key="index">
                      <td style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">{{ formatDate(r)}}</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"> {{ totalGenData[index][0] !== 0 ? $formatNumber(totalGenData[index][0].toFixed(2)) : '' }}</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ totalGenData[index][1] !== 0 ? $formatNumber(totalGenData[index][1].toFixed(2)) : '' }}</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ totalGenData[index][2] !== 0 ? $formatNumber(totalGenData[index][2].toFixed(2)) : '' }}</td>
                 
                   <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40; width: 7%; white-space: normal;" v-for="head in headsar" :key="head.id">
    <!-- {{incomeMap[formatDate(r)] && incomeMap[formatDate(r)][head.sb_rate_head] && incomeMap[formatDate(r)][head.sb_rate_head].amount ||0 }}  -->
    {{ $formatNumber(getIncomeAmount(formatDate(r), head.sb_rate_head))}}
  </td> 
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ totalGenData[index][3] !== 0 ? $formatNumber(totalGenData[index][3].toFixed(2)) : '' }}</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;">{{ totalGenData[index][4] !== 0 ? $formatNumber(totalGenData[index][4].toFixed(2)) : '' }}</td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"> {{ totalGenData[index][5] !== 0 ? $formatNumber(totalGenData[index][5].toFixed(2)) : '' }}
                     
                      </td>
                    </tr>
                    <tr>
                      <th style="text-align: center; border-width: 1px; border-style: solid; border-color: #130f40;">Total:</th>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(cash_total) }}</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(cheque_total) }}</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(full_total) }}</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;" v-for="h in headsar" :key="h.id">
                        <div>{{ $formatNumber(Number(calculateTotal(h.sb_rate_head)).toFixed(2)) }}</div>
                      </td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(stamp_full_total.toFixed(2))}}</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(vat_total.toFixed(2))}}</b></td>
                      <td style="text-align: right; border-width: 1px; border-style: solid; border-color: #130f40;"><b>{{ $formatNumber(discount.toFixed(2))}}</b></td>
                    </tr>
                  </tbody>
                </table>
                <br>
                
                <h6>Prepared By | සකස් කලේ ................................................................................... &nbsp; &nbsp;&nbsp;Checked By |පරික්ෂා කලේ .......................................................................... </h6>
                <h6>Date | දිනය&nbsp;&nbsp;................................................................................&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Date | දිනය &nbsp;&nbsp;................................................................................</h6>
              </div>
            </div>
          </form>
          <div class="form-group">
            <input type="button" value="Print" class="btn" @click="fetchDataAndPrint" />
            <input  type="button" id="excebtn" value="To Excel" class="btn"  @click="exportTableToExcel" :disabled="disableButton" />
            
            
          </div>
        </div>
        <br>
      </div>
      </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import VueBasicAlert from 'vue-basic-alert';
    import * as XLSX from 'xlsx';
    // import * as XLSXStyle from 'xlsx-style';
    export default {
      name: "MonthlyIncome",
      data() {
        return {
          nic: "",
          sabha: "",
          userLevel: "",
          userName: "",
          sabhadetail: [],
         
         
          date: new Date(),
        
          headsar: [],
          cash_total: 0,
          cheque_total: 0,
          full_total: 0,
          stamp_full_total: 0,
          shroffObj: { idate:"" },
          
          incomeMap: {},
          vat_total:0,
          datesofmonth: [] ,// Array to store dates
          selectedMonth:"",
          selectedYear:"",
          discount:0,
          totalGenData: [],
          disableButton:true,
         
         
        };
      },
    
      created() {
        this.getDataFromSessionStorage();
        this.genReport();
        this.getDatesInMonth(this.selectedMonth, this.selectedYear);
        this.fetchTotalGenData();
      },
  //     mounted() {
  //   this.fetchTotalGenData();
  // },
    
      methods: {
        getDatesInMonth(month, year) {
      const daysInMonth = new Date(year, month, 0).getDate();
      this.datesofmonth = [];

      for (let day = 1; day <= daysInMonth; day++) {
        this.datesofmonth.push(new Date(year, month, day));
      }
    },
    formatDate(date) {
        const day = date.getDate().toString().padStart(2, "0");
        const monthcc = (date.getMonth()).toString();
        let month=""
        if(monthcc==0){
             month = 12
        }else{
             month = (date.getMonth()).toString().padStart(2, "0");
        }
          const year = date.getFullYear().toString();
          return `${year}-${month}-${day}`;
    },

    
        async getDataFromSessionStorage() {
          const data = JSON.parse(sessionStorage.getItem('userData'));
          if (data) {
            this.nic = data.nic;
            this.sabha = data.sabha;
            this.userLevel = data.userLevel;
            this.userName = data.userName;
          }
          this.sabhadetail = (await axios.get('/pra_sabha/' + this.sabha)).data;
     
        },
        async fetchTotalGenData() {
      for (let i = 0; i < this.datesofmonth.length; i++) {
        const date = this.formatDate(this.datesofmonth[i]);
        this.totalGenData[i] = await this.totalgen(date);
      }
    },

    
       totalgen: async function(ddate) {
   
        try {
        let sumdata1 = await axios.get('/sumofday/' + this.sabha + '/' + ddate);
        let suminvoice = sumdata1.data;

        let cashtt = 0;
        let cheque = 0;
        let full = 0;
        let stamp_full = 0;
        let vatt = 0;
        let dist=0;

        // Processing and formatting totals
        full = parseFloat(suminvoice[0].totalamount || 0);
        cashtt = parseFloat(suminvoice[0].cashtotal || 0);
        cheque = parseFloat(suminvoice[0].chequetotal || 0);
        stamp_full = parseFloat(suminvoice[0].stamp || 0);
        vatt = parseFloat(suminvoice[0].vat || 0);
        dist = parseFloat(suminvoice[0].discount || 0);

        
        // return [cashtt.toFixed(2), cheque.toFixed(2), full.toFixed(2), stamp_full.toFixed(2), vatt.toFixed(2), dist.toFixed(2)];
        return [cashtt, cheque, full, stamp_full, vatt,dist];
      
        } catch (error) {
            console.error('Error fetching or processing data:', error);
            throw new Error('Failed to fetch or process data.');
        }
     
    
  },
        async genReport() {
      let sabhacode = this.sabha;
      const selectedmon =  (this.shroffObj.idate).split("-")
      this.selectedMonth=selectedmon[1]
      this.selectedYear=selectedmon[0]
      this.getDatesInMonth(this.selectedMonth, this.selectedYear);
      await this.fetchTotalGenData();
      
      try {
        
        const headsFoSabha = await axios.get(`/sbvotes/${sabhacode}`);
          this.headsar = headsFoSabha.data;
  
          let moncash = 0;
          let moncheque = 0;
          let montotal = 0;
          let stamp_total = 0;
          let vat_tot = 0;
          let dis_tot = 0;
  
          this.incomeMap = {};
  
          for (let invoice of this.datesofmonth) {
            const formattedDate = this.formatDate(invoice);
            // let sumdata1 = await axios.get('/invoicesum/' +this.sabha + "/"+this.formatDate(invoice));
            let sumdata1= await axios.get('/sumofday/' + this.sabha + '/' + this.formatDate(invoice));
            this.dailyinvoice = sumdata1.data;

              // if (invoice.invoice_status === 0) {
                  moncash += parseFloat(this.dailyinvoice[0].cashtotal) || 0;
                  moncheque += parseFloat(this.dailyinvoice[0].chequetotal) || 0;
                  montotal += parseFloat(this.dailyinvoice[0].totalamount) || 0;
                  stamp_total += parseFloat(this.dailyinvoice[0].stamp) || 0;
                  vat_tot += parseFloat(this.dailyinvoice[0].vat) || 0;
                  dis_tot += parseFloat(this.dailyinvoice[0].discount) || 0;
  
                  for (let head of this.headsar) {
                      const allinvoiceIncomeheads = await axios.get('/sumofdayvote/'+ this.sabha+ '/' + formattedDate + '/' + head.sb_rate_head);
                      if (!this.incomeMap[formattedDate]) {
              this.incomeMap[formattedDate] = {};
            }

            this.incomeMap[formattedDate][head.sb_rate_head] = parseFloat(allinvoiceIncomeheads.data[0]?.amount) || '';
                      
                      //    if (!this.incomeMap[this.formatDate(invoice)]) {
                      //     this.incomeMap[this.formatDate(invoice)] = {};
                      // }
                      // if (allinvoiceIncomeheads.data && allinvoiceIncomeheads.data.length > 0 && allinvoiceIncomeheads.data[0].amount !== undefined) {
                      //     this.incomeMap[this.formatDate(invoice)][head.sb_rate_head] = parseFloat(allinvoiceIncomeheads.data[0].amount) || 0;
                      // } else {
                      //     this.incomeMap[this.formatDate(invoice)][head.sb_rate_head] = 0;
                      // }
                  }
              // }
          }
  
          this.cash_total = moncash.toFixed(2);
          this.cheque_total = moncheque.toFixed(2);
          this.full_total = montotal.toFixed(2);
          this.stamp_full_total = stamp_total;
          this.vat_total = vat_tot;
          this.discount=dis_tot
          this.disableButton = false;
                   
      } catch (error) {
          console.error('Error generating report:', error);
      }
      
  },
  getIncomeAmount(date, headName) {
      // return this.incomeMap[date] && this.incomeMap[date][headName] !== undefined
      //   ? this.incomeMap[date][headName]
      //   : 0;
      try {
      const value = this.incomeMap[date] && this.incomeMap[date][headName] !== undefined
        ? this.incomeMap[date][headName]
        : 0;

      return typeof value === 'number' && value !== 0 ? value.toFixed(2) : '';
    } catch (error) {
      console.error('Error in getIncomeAmount:', error);
      return '';
    }
    },
   
        calculateTotal(headName) {
          let total = 0;
          this.datesofmonth.forEach(invoice => {
              total += this.incomeMap[this.formatDate(invoice)]?.[headName] || 0;
          });
          return total;
        },

        fetchDataAndPrint() {
  
  const prtHtml = document.getElementById('PrintContent').innerHTML;
  
  // Open the print window
  // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,status=0');
  const WinPrint = window.open('', '', '');
  WinPrint.document.write(`
  ${prtHtml}
  `);
  
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
  },
  exportTableToExcel() {
      // Get the HTML table element
      const table = document.getElementById('PrintContent');
      
      // Convert the table to a worksheet
      const ws = XLSX.utils.table_to_sheet(table);

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate an Excel file and trigger download
      XLSX.writeFile(wb, 'ExportedContent.xlsx');
    }
},
      components: {
        VueBasicAlert
      }
    };
    </script>
   <style scoped>
   .hidden-content {
    display: none; /* Initially hidden */
  }
   .invoice-contain {
       background-color: #fff;
       /* height: 100vh; */
       /* padding: 2rem 25%; */
       padding: 1rem;
       font-size: 16px;
       align-content: center;
       min-height: 72.3vh;
   }
   .invoice-contain .invoice-form-contain {
       background: #fff;
   
   }
   
   .invoice-contain .invoice-form-contain form {
       position: relative;
   }
   .invoice-contain .invoice-form-contain form h3 {
       /* padding-bottom: 1rem; */
       font-size: 1.7rem;
       text-transform: uppercase;
       color: #130f40;
       margin: 0;
   }
   .ttable {
       
       width: 100%;
       border-width:1px;
       border-color : #130f40;
       /* border-style: solid; */
       font-size: 0.9rem;
       /* background-color :white; */
       /* padding-left: 3rem; */
        /* height: 3rem; */
        border-collapse: collapse;
   }
   th  {
       text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  
   
   .table-responsive {
       margin-top: 8vh;
       height: 500px;
   }
   
   .action-btn,
   .cancel-btn,
   .paid-btn {
       width: 100px;
       height: 25px;
       color: white;
       text-transform: capitalize;
   }
   
   .action-btn {
       background-color: #0da9ef;
       margin-right: 10px;
   }
   
   .cancel-btn,
   
   .action-btn:hover {
       background-color: #27ae60;
   }
   
    .invoice-contain .invoice-form-contain form .form-group {
       margin:0;
       font-size: 1rem;
   } 
   
   .invoice-contain .invoice-form-contain form .form-group .error-mess {
       font-size: 1.5rem;
       position: relative;
       color: rgb(243, 47, 47);
       margin: 0;
       padding: 0;
   }
   .invoice-contain .btn {
       background: rgb(223, 80, 14);;
       color: rgb(240, 234, 231);
       border: 1rem;
       padding: 1rem;
       font: inherit;
       cursor: pointer;
       outline: 1rem;
       text-align: center;
      
   }
   .invoice-contain .btnrow{
      align-content: center;
      padding-left: 25rem;
   }
  
   .scroll-container {
    width: 100%; /* Set the width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent content from wrapping */
  }
  /*
  //.scroll-content {
     Optional: Set width if necessary 
  //}*/
   </style>
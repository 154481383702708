<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Add Assessment tax PIV</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Search Property</h3></b>
                </div>
                <h5><b>Select by Category</b></h5>
               <div class="row">
                <div class="col-sm-3">
                    
                   <label for="Splace">Ward
                   </label>
                       <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="street">Street
                   </label>
                       <div><select v-model="CatObj.street" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                            v-bind:value =m.street_id>
                               <div>{{m.street_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="cat">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-3">
                   <label for="s">
                   </label>
                   <input type="button" value="Search" id="Addpay" class=" btn" @click="SearchByCat()"  /> 
               </div>
               </div>
                <div class="row">                    
                    <div class="col-sm-3">
                        <h5><b>Select by property ID</b></h5>
                        <label for="usedn"> Property ID:
                        </label>
                        <div><select v-model="CatObj.propsearch" id="serchprop" class="form-control" >
                            <option value="" selected disabled>
                                        Choose
                                    </option>
                                    <option v-for="p in sabaProp" :key="p.property_id" id="Hid"  
                                    v-bind:value =p.property_id>
                                    <div>{{p.property_id }}</div> 
                                    </option>
                                </select>
                            <!-- <p class="error-mess" v-if="errObj.propErr.length > 0">{{ errObj.propErr[0] }}</p> -->
                        </div>
                    </div>
                    <div class="Addbtn col-sm-3">
                   <br>
                   <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
               </div>
                </div>
            
           <br>
           <div v-if="showtbl" >
           <div   style="width: 100%; text-align: center;">
                <h3>Select a Property to Add PIV</h3>
            </div>
           <div class="row">
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Property ID</th>
                       <th>Assessment Num</th>
                       <th>Address</th>
                       <th>Rate</th>
                       <th>Select</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td>{{ s.property_id  }}</td>
                       <td>{{ s.asses_num  }}</td>
                       <td>{{ s.prop_address }}</td>
                       <td>{{ s.prop_rate }}</td>
                       <td> <input
                                type="radio"
                                name="propertySelection"
                                :value="s.property_id"
                                v-model="selectedProperty"
                                 @change="populateCustomerDetails(s.property_id)"
                            /></td>
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
        <!-- <div v-if="selectedProperty" style="margin-top: 10px; text-align: center;">
            <p>Selected Property ID: {{ selectedProperty }}</p>
         </div> -->
           </form>
           <!-- piv -->
            <div class="register-container">
           <div class="register-form-container">
           <form id="userForm"  novalidate autocomplete="off">
               <div class="heading">
                   <h3>Invoice Details </h3>
               </div>
               <h4>Assesment Property Details</h4>
                   <div class="row">
                    <div class="col-sm-8">
                        <label for="cName">Name:</label>
                        <textarea name="cName" id="cName" class="form-control" v-model="cusObj.name" rows="2" readonly></textarea>
                    </div>
                       <div class="col-sm-4">
                           <label for="cAddress">Address:</label>
                            <textarea name="cAddress"  id="cAddress" class="form-control" v-model="cusObj.address" readonly ></textarea>
                       </div>
                   </div>
                   <div class="form-group" >
<table class="ttable">
  <thead>
    <tr>
      <th>Quarter</th>
      <th>Payable Amount</th>
      <th>Paid Amount</th>
      <th>Fine</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="p in propDetails" :key="p.property_id">
      <!-- First Quarter -->
      <tr>
        <td style="text-align: left;"><b>First Quarter Amount</b></td>
        <td>{{ p.q_one_val }}</td>
        <td>{{ getPaidAmount(p.property_id, 1) }}</td>
        <td>{{ getFineAmount(p.property_id, 1) }}</td>
      </tr>

      <!-- Second Quarter -->
      <tr>
        <td style="text-align: left;"><b>Second Quarter Amount</b></td>
        <td>{{ p.q_two_val }}</td>
        <td>{{ getPaidAmount(p.property_id, 2) }}</td>
        <td>{{ getFineAmount(p.property_id, 2) }}</td>
      </tr>

      <!-- Third Quarter -->
      <tr>
        <td style="text-align: left;"><b>Third Quarter Amount</b></td>
        <td>{{ p.q_three_val }}</td>
        <td>{{ getPaidAmount(p.property_id, 3) }}</td>
        <td>{{ getFineAmount(p.property_id, 3) }}</td>
      </tr>

      <!-- Fourth Quarter -->
      <tr>
        <td style="text-align: left;"><b>Fourth Quarter Amount</b></td>
        <td>{{ p.q_four_val }}</td>
        <td>{{ getPaidAmount(p.property_id, 4) }}</td>
        <td>{{ getFineAmount(p.property_id, 4) }}</td>
      </tr>
    </template>
  </tbody>
</table>

<br>
                   </div>                       
                   <h4>Payment Details</h4>
                   <div class="form-group">
                   <label for="iHead">Income Head:
                   </label>
                 <select v-model="cusObj.headid" id="iHead" class="form-control">
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option>
                   </select>

                   <!-- <p class="error-mess" v-if="errIncomeObj.iheadErr.length > 0">{{ errIncomeObj.iheadErr[0] }}</p>  -->
               </div>
               <div>
                <div class="form-group">
                    <label for="desc">Description:</label>
                    <input type="text" name="des" id="desc" class="form-control" v-model="cusObj.descr" />
                </div>
               </div>
           </form>
       </div>
       </div>
</div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessPIV",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { ward:"", street:"",  mainc:"" ,propsearch:""},
           errObj:{ wardErr:[], stretErr:[],maincErr:[]},
           savedWards:[],
           savedStreets:[],
           itemsPerPage: 10,
            currentPage: 1,
            maincate:[],
            sabaProp:[],
            propertydetails:[],
            showtbl:false,
            selectedProperty: null,
            cusObj:{name:"",address:"",headid:"",descr:""},
            ownerdetails:[],
            sabhaIncomeHead:[],
            propDetails:[],
            paymentData: {}, // Store paid amount and fine for each property and quarter
            response:[],
       };
   },
   created() {
       this.getDataFromSessionStorage()

   },

   computed:{
       ...mapState(["user"]),
      
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.propertydetails)) {
        return this.propertydetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.propertydetails.length / this.itemsPerPage);
    },

   },
   async mounted() {
    try {
        this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

    } catch (error) {
        console.error("Error fetching data", error);
    }

    await this.loadPaymentData();

},
   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                try{
                this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
            } catch (error) {
                console.error("Error fetching data", error);
            }
           this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
           //incomeheads
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabha)
           this.sabhaIncomeHead = Sabhadata.data
       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    getCategoryName(cat_id) {
        const category = this.maincate.find(m => m.cat_id === cat_id);
        return category ? category.category_name : 'Unknown';
    },

nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},
//fectch both
    async fetchData(url) {
        try {
            const response = await axios.get(url);
            this.propertydetails = response.data;
            if (this.propertydetails.length > 0) {
            this.showtbl = true;
            } else {
            this.message = "No such record";
            this.showtbl = false;
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            this.message = "Failed to load details. Please try again later.";
        }
        },

       resetCheckErr: function () {
        this.errObj.wardErr =[];
        this.errObj.stretErr=[];
        this.errObj.maincErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.ward) {
               this.errObj.wardErr.push("required");
           }
           if (!this.CatObj.street) {
               this.errObj.stretErr.push("required");
           }
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
       },
       async SearchByCat(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                this.showtbl = false;
            this.message = ""; // Clear any previous messages
            this.propertydetails = []; // Clear previous data
            this.CatObj.propsearch=""
            this.selectedProperty= null
                const url = `/proplist/${this.sabha}/${this.CatObj.ward}/${this.CatObj.street}/${this.CatObj.mainc}`;
                await this.fetchData(url);
            }
        },
    async SearchById() {
          this.showtbl = false;
        this.message = ""; // Clear any previous messages
        this.propertydetails = []; // Clear previous data
        this.CatObj.ward="",
        this.CatObj.street="",
        this.CatObj.mainc="",
        this.selectedProperty= null
    try {
        const url = `/sabapropid/${this.sabha}/${this.CatObj.propsearch}`;
        
        // Fetch data from API
        const response = await axios.get(url);

        if (response.data.length > 0) {
        this.propertydetails = response.data;
        this.showtbl = true;
        } else {
        this.message = "No such record";
        this.showtbl = false;
        }
    } catch (error) {
        console.error("Error fetching details:", error);
        this.message = "Failed to load details. Please try again later.";
        this.showtbl = false;
    }
    },
    //selected property's details
    // async populateCustomerDetails(property) {
    //   // Populate the form fields with the selected property's details
      
    //   this.ownerdetails = (await axios.get('/proownerdetatails/'+property+"/"+this.sabha)).data;
    //   this.cusObj.name = this.ownerdetails.owner_name || "";
    //   this.cusObj.phone = this.ownerdetails.owner_contact || "";
    //   this.cusObj.address = this.ownerdetails.owner_address || "";
    // },
    async populateCustomerDetails(property) {
  try {
    const response = await axios.get(`/prownerdetails/${property}/${this.sabha}`);

    if (response.data && response.data.length > 0) {
      this.ownerdetails = response.data;

      // Join multiple names into a single string (e.g., comma-separated)
      this.cusObj.name = this.ownerdetails.map(owner => owner.cus_name).join(', ') || "";

      // Assign the first address found
      this.cusObj.address = this.ownerdetails[0].prop_address || "";
    } else {
      console.warn("No owner details found.");
      this.$refs.alert.showAlert('error', 'No owner details found');
      this.cusObj = { name: "", address: "" }; // Clear form
      this.message = "No owner details available.";
    }
    //get property payment details to show in the table
    this.propDetails =  (await axios.get('/sabapropid/'+ this.sabha +"/"+ this.CatObj.propsearch)).data

  } catch (error) {
    console.error("Error fetching owner details:", error);
    this.message = "Failed to load owner details. Please try again later.";
  }
},
// load payments
//TODO LOAD THIS PAYMENT USING THE NEW VIEW. CHECK THE LAST ADDED METHOD TO SEE IF ITS CORRECT./OR NEEDED
//     CHECK IF propertyOwnerByID CAN BE CHANGED TO GET THOSE DATA AT ONCE in populateCustomerDetails method.
async loadPaymentData() {
  const currentYear = new Date().getFullYear(); // Get current year
  try {
    // Create a list of promises for all property-quarters combinations
    const requests = this.propDetails.flatMap(p =>
      [1, 2, 3, 4].map(quarter =>
        axios
          .get(`/pqpay/${p.property_id}/${this.sabha}/${quarter}/${currentYear}`)
          .then(response => {
            const paymentInfo = response.data[0] || {}; // Handle missing data

            const key = `${p.property_id}_${quarter}`;
            console.log(`Setting payment data for key: ${key}`, paymentInfo);

            // Store the payment info in the paymentData object
            this.$set(this.paymentData, key, {
              paidAmount: paymentInfo.paid_amount || 'N/A',
              fineAmount: paymentInfo.fine_amount || 'N/A',
            });
          })
          .catch(error => {
            console.error(
              `Error fetching data for property ${p.property_id}, quarter ${quarter}:`,
              error
            );
          })
      )
    );

    // Wait for all requests to complete
    await Promise.all(requests);

    console.log('Final Payment Data:', this.paymentData);
  } catch (error) {
    console.error('Error loading payment data:', error);
  }
},
    getPaidAmount(propertyId, quarter) {
      const key = `${propertyId}_${quarter}`;
      console.log(`Fetching paid amount for key: ${key}`, this.paymentData[key]);
      return this.paymentData[key]?.paidAmount || 'N/A';
    },
    getFineAmount(propertyId, quarter) {
      const key = `${propertyId}_${quarter}`;
      console.log(`Fetching fine amount for key: ${key}`, this.paymentData[key]);
      return this.paymentData[key]?.fineAmount || 'N/A';
    },
   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     text-align: center;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      padding: 2%;
  }
  th  {
     text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 90vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.3rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
   font-size: 1.2rem;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* second form */
.register-container {
    padding-top: 1.0rem;
    font-size: 16px;
     /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.3rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;
}

</style>
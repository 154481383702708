<template>
    <div class="menu-section">
       
<!-- start -->
<vue-basic-alert :duration="400" :closeIn="2000" ref="alert" />
<div class="register-container">
       <div class="register-form-container">
           <form id="userForm" @submit="handleSubmit1" novalidate autocomplete="off">
               <div class="heading">
                   <h3>Invoice Details </h3>
               </div>
               <div class="container">
               <h4>Customer Details</h4>
                   <label for="cNic">NIC Number: 
                   </label>
                  <span> <input type="text" name="cNic" placeholder="Customer NIC" id="cNic" class="form-control"
                   @input ="showCustomer" v-model="cusObj.nic"/>
                       <p class="error-mess" v-if="errObj.nicErr.length > 0">{{ errObj.nicErr[0] }}</p>
                   </span> 
               </div>
               
               <div class="container">
                   <div class="row">
                       <div class="col-sm">
                           <label for="cName">Name:
                                       </label>
                                       <input type="text" name="cName" placeholder="Customer Name" id="cName" class="form-control"
                                           v-model="cusObj.name" />
                                           <p class="error-mess" v-if="errObj.nameErr.length > 0">{{ errObj.nameErr[0] }}</p>
                       </div>
                       <div class="col-sm">
                           <label for="cPhone">Contact:
                                       </label>
                                   <input type="text" name="cPhone" placeholder="Contact number" id="cPhone" class="form-control" 
                                       v-model="cusObj.phone" />
                                       <p class="error-mess" v-if="errObj.phoneErr.length > 0">{{ errObj.phoneErr[0] }}</p>
                       </div>
                       <div class="col-sm">
                           <label for="cAddress">Address:
                                       </label>
                                   <input type="text" name="cAddress" placeholder="Customer Address" id="cAddress" class="form-control" 
                                       v-model="cusObj.address" />
                                       <p class="error-mess" v-if="errObj.addErr.length > 0">{{ errObj.addErr[0] }}</p>
                       </div>
                   </div>
               </div>
                           <div class="form-group">
                               <input type="submit" value="Add" class="btn" id ="Add" />
                           </div>

           </form>
       </div>

<!-- end -->
</div>

           <!-- <div class="col-sm-12"> -->
               <!-- <div class="row">
                   <div class="menu-tabs">
                       <input type="button" id="allFilterFoodBtn" name="allFilterFoodBtn" value="Income"
                           class="menu-tab-item" @click="filterFoodBtn($event)" />
                       
                   </div>
               </div> -->
<!-- start -->
<div class="shroff-container">
       <div class="shroff-form-container">
           <form id="addIncomeForm" @submit="handleSubmit2" novalidate autocomplete="off">
       
               <h4>Payment Details</h4>
               <div class="form-group">
                   <label for="iHead">Income Head:
                   </label>
                 <select v-model="incomeObj.headid" id="iHead" class="form-control" @change ="checkShop()" >
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates"  
                           v-bind:value =[ratehead.emp_sb_rates,ratehead.subjecttype]  >
                             {{ ratehead.emp_sb_rates  }} - {{ratehead.sb_rate_head_name}}
                       </option>
                   </select>

                   <p class="error-mess" v-if="errIncomeObj.iheadErr.length > 0">{{ errIncomeObj.iheadErr[0] }}</p> 
               </div>
                <!-- Shop Detail -->
               <div class="form-group" id="shopdetail" style="display: none;">
                
                  <table class="ttable" >
               <thead>
                   <tr>   
                       <th>Year Month</th>
                       <th>Shop Number</th>
                       <th>Shop Place</th>
                       <th>Monthly rent</th>
                       <th>Arrears</th>
                       <!-- <th>Fine</th> -->
                       <th>Select</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" s in shopData" :key="s.shop_details_id ">
                        <td>{{ currentDate.getFullYear() }} - {{ getMonthName(currentDate.getMonth() ) }}</td>
                       <td>{{ s.shop_number  }}</td>
                       <td>{{ s.place }}</td>
                       <!-- <td>{{ getPaymentOfMonth(s.pay_month ,s.monthly_shop_rent)  }}</td> -->
                        <td>{{ s.monthly_shop_rent }}</td>
                       <td>{{ DiffOfMonths(s.pay_month ,s.monthly_shop_rent,s.arrears) }}</td>
                       <!-- <td>{{ s.fine }}</td> -->
                       <!-- <td><input type="checkbox" id="shopcheck"  v-model="incomeObj.selectedShops" :true-value="s.shop_details_id" @Change="selectShops()"></td> -->
                       <td><input type="checkbox" id="shopcheck"  v-model="selectedShops[s.shop_details_id]" @Change="selectShops(s.shop_details_id)"></td>
                      
                   </tr>
               </tbody>
           </table>
          <p>If monthly Rent is Zero this month payment is done.</p> 
           <p>මුදල් ගෙවීම සඳහා අවශ්‍යය කඩ කාමර අංකය තේරීම අත්‍යාවශ්‍ය වේ.</p>
           <!-- pay month -->
            <p style="color: blue;font-size: small;">මුදල් ගෙවන අවසාන මාසය තෝරන්න. ඉදිරි මාස කිහිපයක් සඳහා ගෙවන්නේ නම් එම අවසාන මාසයද/ පෙර මාස කිහිපයක් සඳහා ගෙවන්නේ නම් එම අවසාන මාසයද තෝරන්න ‍‍| Please select month for payment. If you are paying ahead for several months, select ending month. If you are paying for previous months, select ending month</p>
            <p style="font-size: 1.3rem; color: black;"> Ex: paying for March,April and May; select May</p>
           <label for="monthSelect">Payment month:</label>
           <input type="month" name="imon"  id="imon" class="form-control"
                        v-model="incomeObj.emonthyr" />
                   
                    <p class="error-mess" v-if="errIncomeObj.monyrErr.length > 0">{{ errIncomeObj.monyrErr[0] }}</p>
               </div>
               <div id="grandtotal" style="display: none;">ගෙවීමට ඇති මුළු මුදල(රුපියල්) : {{ totalAmountRent.toFixed(2) }}</div>
               <div class="form-group">
                   <label for="iDes">Description: {{ incomeObj.shopData }}
                   </label>
                   <input type="textarea" name="iDes"  id="iDes" class="form-control"
                    v-model="incomeObj.des" />
                    <p class="error-mess" v-if="errIncomeObj.iDesErr.length > 0">{{ errIncomeObj.iDesErr[0] }}</p>
               </div>
               
               <!-- <div id="fine" style="display: none;">10 වෙනි දිනට පසු ගෙවීමේදී දඩ මුදල(රුපියල්) : {{ fine.toFixed(2) }}</div> -->
            
        
               <div class="row">
                   <div class="col-sm-2">
                   <label for="iAmount">Amount:
                   </label>
                   <input type="text" name="iAmount"  id="iAmount" class="form-control" 
                     v-model="incomeObj.amount" />
                    <p class="error-mess" v-if="errIncomeObj.amountErr.length > 0">{{ errIncomeObj.amountErr[0] }}</p>
               </div>
               <div class="col-sm-2">
                   <label for="iStamp">Stamp Fee:
                   </label>
                   <input type="text" name="iStamp"  id="iStamp" class="form-control" 
                       v-model="incomeObj.stamp" />
                   <p class="error-mess" v-if="errIncomeObj.stampErr.length > 0">{{ errIncomeObj.stampErr[0] }}</p>
               </div>
               <div class="col-sm-2">
                   <label for="iDisc">Discounts:
                   </label>
                   <input type="text" name="iDisc"  id="iDisc" class="form-control" 
                       v-model="incomeObj.discounts" />
                   <p class="error-mess" v-if="errIncomeObj.discErr.length > 0">{{ errIncomeObj.discErr[0] }}</p>
               </div>
               <div class="col-sm-2">
                    <label for="iDisc">VAT: (%)
                    </label>
                    <input type="text" name="iVat" id="iVat" class="form-control" 
                    v-model="incomeObj.vat"    />
                   <br>
                </div>
                <!-- <div id="ifineseg" style="visibility: hidden;" class="col-sm-2" >
                    <label for="ifine">Fine
                    </label>
                    <input type="number" name="ifine" id="ifine" class="form-control" 
                    v-model="incomeObj.efine"    />
                </div> -->
               <div class="Addbtn col-sm-4">
                   <label for="iRates">
                   </label>
                   <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable" /> 
               </div>
           </div>
           <!-- <div id="arreas" style="display: none;">ගෙවීමට ඇති හිඟ මුදල (රුපියල්) : {{ total_arresrs.toFixed(2) }}</div> -->
          
           <br>
           <div class="row">
               <table class="ttable" >
               <thead>
                   <tr>   
                       <th>NIC/Ref: No</th>
                       <th>Income head</th>
                       <th>Description</th>
                       <th>Amount</th>
                       <th>Stamp fee</th>
                       <th>Discounts</th>
                       <th>Vat(%)</th>
                       <th>Remove</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" r in allreceipt" :key="r.id">
                        <td>{{ r.cus_nic }}</td>
                       <td>{{ r.sb_rate_head }}</td>
                       <td style="width: 30%;">{{ r.description }}</td>
                       <td>{{ $formatNumber(r.amount) }}</td>
                       <td>{{ $formatNumber(r.stamp) }}</td>
                       <td>{{ $formatNumber(r.discount) }} </td>
                       <td>{{ $formatNumber(r.vat) }}</td>
                       <td><input type="button" @click="deleteReceipt(r.id)" value="X" class=" bbtn"></td>
                     
                   </tr>
               </tbody>
           </table>
           </div>
           
           </form>
       </div>
</div>
<!-- end -->
</div>
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
export default {
   name: "SubjectDashboard",

   data() {
       return {
           cusObj : { nic: "", name: "", phone:"0", address:"" },
           errObj : {nicErr:[], nameErr:[], phoneErr:[], addErr:[]},
           matchCus : undefined,
           theUser :[],
           nic:"",
           sabha: "",
           userLevel:"",
           incomeObj : { headid:"", des:"", amount:"0.00", stamp:"0.00", discounts:"0.00", vat:"0", selectedShops:"", prepairedby:"",efine:"0",emonthyr:"0"},
           errIncomeObj:{iheadErr:[], iDesErr:[], amountErr:[], stampErr:[], discErr:[],monyrErr:[]},
           sabhaIncomeHead : undefined,
           ratehead : '',
           tablear:[],
           allreceipt:[],
           arrayy: undefined,
            shopData:[],
            shopDetails:[],
            // date : new Date(),
            currentDate: new Date(),
            total_arresrs:0,
            fine :0,
            totalAmountRent:0,
            surname:'',
            Nic12:0,
            selectedShops:[],
            shopList:[],
            sabhaCode:'',
            shouldDisable: false,
            shopdid:0,
            checkval:0,totalAmountShop:0,
            invdescription:'',
            invshopdid:0,
            userName:"",
            selectedNumber:'',
            selectyr:"",
            selectmon:"",
           
       };
   },
   created() {
       this.getDataFromSessionStorage()
      //  this.showCustomer()
     
   },
   computed : {
            isGreaterThan10th() {
                return this.currentDate.getDate() > 10;
                },
            
        },

   methods: {
    formattedDate() {
            // const day = this.date.getDate().toString().padStart(2, "0");
            // const month = (this.date.getMonth() + 1)
            //   .toString()
            //   .padStart(2, "0");
            // const year = this.date.getFullYear().toString();
            // // this.defaultdate =`${year}-${month}-${day}`
            // return `${year}-${month}-${day}`;
            let date = new Date()
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
          },
       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                           this.userName = data.userName
                       }
           this.sabhaCode = this.sabha
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ this.sabhaCode)
           this.sabhaIncomeHead = Sabhadata.data
           this.incomeObj.prepairedby =  this.userName
           this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
       },
       getMonthName:function (monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber);

            return date.toLocaleString('en-US', { month: 'long' });
            },

          getPaymentOfMonth:function( paymonth1,rent) {
            
            let Mpayment =rent;

                  if (paymonth1== this.currentDate.getMonth()) {
                  Mpayment=0
                  }
                  else{
                    Mpayment=rent
                  }

                  return Mpayment;
         },
         addZero(num) {

            num = num.toString();
                return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
            },
            addZeroPadding: function() {
            // this.len= this.loginObj.nic.charAt(9).toUpperCase()
            // Check if the user input is a valid number
            // if (!isNaN(parseInt(this.cusObj.nic)) && this.cusObj.nic !== null && this.cusObj.nic.charAt(9).toUpperCase() === 'V') {
if (!isNaN(parseInt(this.cusObj.nic)) && this.cusObj.nic !== null && (this.cusObj.nic.charAt(9).toUpperCase() === 'V' || this.cusObj.nic.charAt(9).toUpperCase() === 'X')) {
                
                this.paddedNum = this.addZero(this.cusObj.nic);

            }else{
                this.paddedNum=parseInt(this.cusObj.nic);
            }
            return this.paddedNum
            },

async showCustomer() {
    let letter_first= (this.cusObj.nic).charAt(0)
            if(letter_first=='R'){
                this.Nic12=this.cusObj.nic
            }else{
            // this.cus_nic = JSON.parse(sessionStorage.getItem("cus_nic"))
            this.Nic12 = this.addZeroPadding(this.cusObj.nic); 
            }
         this.Nic12 = this.addZeroPadding();
       this.theUser= (await axios.get('/customers/'+this.Nic12+"/"+this.sabhaCode)).data;
       if(this.theUser.length>0)
        {  
        
           this.cusObj.name = this.theUser[0].cus_name;
           this.cusObj.phone = this.theUser[0].cus_contact;
           this.cusObj.address = this.theUser[0].cus_address;
           document.getElementById("Add").style.visibility ="hidden" ;
         //   this.cusObj.phone = this.theUser[0].cus_contact;
         //   this.cusObj.address = this.theUser[0].cus_address;
         //   document.getElementById("Add").style.visibility ="hidden" ;
        }
        else{
           document.getElementById("Add").style.visibility ="visible" ;
           this.cusObj.name = " ";
           this.cusObj.phone = " ";
           this.cusObj.address = " "; 
        }
      //  this.theUser= (await axios.get('/customers/194535002627' )).data;
    //    this.allreceipt = (await axios.get('/pivbyidrow/'+this.Nic12+'/'+this.nic)).data
    this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
       //shopdata
      
         //   this.surname= this.theUser.Cus_name+"/"+this.theUser.cus_name;
         this.shopData = (await axios.get('/shopdata/' +this.Nic12 +"/"+ this.sabha )).data
       
  },

       async addToTable(e){
       
          //
          this.checkForm2();
 //arreas of shop payment
// this.totalAmountShop =0;

            if(this.incomeObj.headid[1]=='shoprent'){
                let totalpayamount=0;
// this.Nic12 = this.addZeroPadding();
 let shopData1 = (await axios.get('/shopdata/' +this.Nic12 +"/"+ this.sabha )).data
 
            let monthlyrent = shopData1[0].monthly_shop_rent
            let pmonth = shopData1[0].pay_month
            
            let checkMonth = this.getPaymentOfMonth(pmonth, monthlyrent);
                             if (checkMonth==0) {
                                 this.totalAmountShop =0
                             } else {
                                    this.totalAmountShop =parseFloat(monthlyrent)
                            }
                        //     else if (this.isGreaterThan10th && checkMonth==0){

                        //     //   this.fine = parseFloat(monthlyrent * 0.1);
                        //       this.totalAmountShop = parseFloat(this.fine)
                        //    }
                        //    else if (this.isGreaterThan10th && checkMonth>0){
                        //     this.fine = parseFloat(monthlyrent * 0.1);
                        //     this.totalAmountShop = parseFloat(monthlyrent) + parseFloat(this.fine)

                        //    }
                      let cur_ar = this.DiffOfMonths(shopData1[0].pay_month,shopData1[0].monthly_shop_rent,shopData1[0].arrears)
            // let checkMonth = this.getPaymentOfMonth(pmonth, monthlyrent);
                totalpayamount=parseFloat(this.totalAmountShop)+parseFloat(cur_ar)
            let today_arrears = parseFloat(totalpayamount)-parseFloat(this.incomeObj.amount)
            this.total_arresrs=parseFloat(today_arrears)
            // this.invdescription=this.incomeObj.des + " ගෙවීමට ඇති හිඟ මුදල (රුපියල්) : "+ this.total_arresrs.toFixed(2) + ". මෙම මස 10 වෙනි දිනට පසු ගෙවීමේදී දඩ මුදල(රුපියල්) :" + this.fine.toFixed(2) 

            // this.invdescription=this.incomeObj.des + " ගෙවීමට ඇති හිඟ මුදල (රුපියල්) : "+ this.total_arresrs.toFixed(2)  
            this.invdescription=this.incomeObj.des  
                this.invshopdid=this.shopdid
            }else{
                this.invdescription=this.incomeObj.des
                this.invshopdid=0
            }
           
     if (!this.checkEmptyErr2()) {
         e.preventDefault2();
     } else {
            const selectedmonyr =(this.incomeObj.emonthyr).split("-")
            this.selectyr= selectedmonyr[0]
            this.selectmon= selectedmonyr[1]
            let paymentmonth="" 
            let paymentyear=""
            if(this.incomeObj.headid[1]=='shoprent'){
                 paymentmonth =  this.selectmon
                paymentyear =this.selectyr
            }else{
                 paymentmonth =  this.currentDate.getMonth()
                paymentyear = this.currentDate.getFullYear()
            }
         let data ={
                    sabha_code: this.sabha,
                    cus_nic : this.Nic12,
                   cus_name : this.cusObj.name,
                   cus_contact : this.cusObj.phone,
                   cus_address:this.cusObj.address,
                   sb_rate_head : this.incomeObj.headid[0],
                   description : this.invdescription,
                   amount : parseFloat(this.incomeObj.amount).toFixed(2),
                   stamp : parseFloat(this.incomeObj.stamp).toFixed(2),
                   discount : parseFloat(this.incomeObj.discounts).toFixed(2),
                   shoptotalarrears: parseFloat(this.total_arresrs).toFixed(2),
                   paymonth:paymentmonth,
                    // this.selectedNumber,
                //    paymonth:this.selectmon,
                   vat :parseFloat(this.incomeObj.vat).toFixed(2),
                   shopdid:this.invshopdid,
                   date : this.formattedDate() , //new Date() ,this.formattedDate(),
                   sub_nic : this.nic,
                  }
                   await axios.post("/receipt/", data)
                //    this.allreceipt = (await axios.get('/pivbyidrow/' +this.Nic12+'/'+this.nic)).data
                this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
                  //  this.incomeObj.headid="",
                  
           // save to temporary_invoice_keep 
            let temporarydata ={
                sabha:this.sabha,
                 cus_nic : this.Nic12,
                   cus_name : this.cusObj.name,
                   cus_contact : this.cusObj.phone,
                    cus_address:this.cusObj.address,
                    sb_rate_head : this.incomeObj.headid[0],
                    description : this.invdescription,
                  amount : parseFloat(this.incomeObj.amount).toFixed(2),
                    stamp : parseFloat(this.incomeObj.stamp).toFixed(2),
                    discount : parseFloat(this.incomeObj.discounts).toFixed(2),
                    shoptotalarrears: parseFloat(this.total_arresrs).toFixed(2),
                    paymonth:paymentmonth,
                    // paymonth:this.selectmon,
                    vat :parseFloat(this.incomeObj.vat).toFixed(2),
                    shopdid:this.invshopdid,
                    date : this.formattedDate() , //new Date() ,this.formattedDate(),
                    sub_nic : this.nic,
                   }
                    await axios.post("/tempkeep/", temporarydata)
                    this.incomeObj.des="",
                   this.incomeObj.amount="0.00",
                   this.incomeObj.stamp="0.00",
                   this.incomeObj.discounts="0.00",
                   this.incomeObj.vat="0"

                   //shop details table month and year update
                   let shopdetails = {
                    arrears:parseFloat(this.total_arresrs).toFixed(2),
                    // pay_month: this.getInsertMonthName(new Date().getMonth()),
                    pay_month:paymentmonth,
                    pay_year:paymentyear,
                   }
                   await axios.put("/uparr/"+this.invshopdid,shopdetails)
                  
         }
//         else {
//             this.$refs.alert.showAlert('error', 'Something Went Wrong')
//             // this.cusObj.name = this.theUser.cus_name;
          
//         }
//     }
          
           
                   
       },
       DiffOfMonths: function(pm, cr, current_arrears) {
   if (pm < 0 || pm > 11) {
      throw new Error('Month must be between 0 and 11');
   }

   let currentDate = new Date();
   let currentMonth = (currentDate.getMonth()-1);
   let currentYear = currentDate.getFullYear();
   let currentYearMonth = currentYear * 12 + currentMonth;
   let pmInCurrentYear = currentYear * 12 + pm;
   let monthDifference = currentYearMonth - pmInCurrentYear;
   let monthDiff = Math.max(0, monthDifference);
   let curArrears = ((parseFloat(cr) * monthDiff)) + parseFloat(current_arrears);

   return curArrears.toFixed(2);
},
//        DiffOfMonths: function(pm, cr,current_arrears) {
//                   // this.curArrears = cr;
//         //           let currentMonth = this.currentDate.getMonth();
//         //           let currentYear = this.currentDate.getFullYear();

//         //         let currentYearMonth = currentYear * 12 + currentMonth;
//         //         let pmInCurrentYear = (currentYear - 1) * 12 + pm;
//         //         if (pm <= currentMonth) {
//         //                 pmInCurrentYear = (currentYear - 1) * 12 + (pm + 12);
//         //             }
//         //             if (pm === currentMonth) {
//         //                 return 0;
//         // }

//         if (pm < 0 || pm > 11) {
//         throw new Error('Month must be between 0 and 11');
//       }

//       let currentDate = new Date(); // Get the current date
//       let currentMonth = currentDate.getMonth(); // Current month (0-based index)
//       let currentYear = currentDate.getFullYear(); // Current year

//       // Convert the current date to a number representing months since year 0
//       let currentYearMonth = currentYear * 12 + currentMonth;

//       // Convert the provided month to a number representing months since year 0
//       let pmInCurrentYear = currentYear * 12 + pm;

//       // Calculate the difference in months
//       let monthDifference = currentYearMonth - pmInCurrentYear;

//       // Return the maximum of 0 and the calculated difference to avoid negative values
//     //   return Math.max(0, monthDifference);
    

// // Calculate the absolute difference in months
// // this.monthDiff = Math.abs(currentYearMonth - pmInCurrentYear);
// this.monthDiff = Math.max(0, monthDifference);

//                 //  this.curArrears =((parseFloat(cr) * this.monthDiff)-parseFloat(cr))+parseFloat(current_arrears) ;
//                  this.curArrears =((parseFloat(cr) * this.monthDiff))+parseFloat(current_arrears) ;
//                //  return monthDiff
//                   return this.curArrears.toFixed(2);
//                },
               
      async deleteReceipt(id){
        let result = confirm("Confirm delete?")
        if(result == true){
         this.Nic12 = this.addZeroPadding();
           await axios.delete("/singlerece/" +  id );
        //    this.allreceipt = (await axios.get('/pivbyidrow/' +this.Nic12+'/'+this.nic)).data
        this.allreceipt =  (await axios.get('/ownpiv/'+ this.nic +"/"+ this.sabhaCode)).data
        }
       },




       async getMatchCus(nic,s_code)
       {
           await axios.get('/customers/'+ nic+'/'+s_code);
       },
       checkShop(){
         if(this.incomeObj.headid[1]=='shoprent'){
            document.getElementById('shopdetail').style.display ='block';
            // document.getElementById('ifineseg').style.visibility ="visible"
            document.getElementById('arreas').style.display ='block';
           
          
           
            // block description

         this.shouldDisable = true
         this.incomeObj.des=""
         this.shopdid=0
         }else{
            document.getElementById('fine').style.display ='none';
        document.getElementById('grandtotal').style.display ='none';
        document.getElementById('shopdetail').style.display ='none';
            document.getElementById('arreas').style.display ='none';
     
            this.shouldDisable = false
                  this.incomeObj.des=""
                //    this.incomeObj.amount="0.00",
                //    this.incomeObj.stamp="0.00",
                //    this.incomeObj.discounts="0.00"
                //    this.incomeObj.vat="0"
         }
        
       },
       async selectShops(shopid){
        // document.getElementById('fine').style.display ='block';
        document.getElementById('grandtotal').style.display ='block';
      
      
          //shopdataid
         //  this.shopDetails= (await axios.get('/shopdataid/' +this.incomeObj.selectedShops)).data;
          this.shopDetails= (await axios.get('/shopdataid/' +shopid)).data;
          this.incomeObj.des= this.currentDate.getFullYear()+"-" + this.getMonthName(this.currentDate.getMonth() )+" "  + this.shopDetails[0].place +" - "+ this.shopDetails[0].shop_number +" සඳහා " 
         
          this.shopList = (await axios.get('/shopdataid/' +shopid)).data
    //    this.total_arresrs = parseFloat(this.shopList[0].arrears)
    this.total_arresrs = this.DiffOfMonths(this.shopList[0].pay_month,this.shopList[0].monthly_shop_rent,this.shopList[0].arrears)

       let checkMonth1 = this.getPaymentOfMonth(this.shopList[0].pay_month, this.shopList[0].monthly_shop_rent);
    //    if (!this.isGreaterThan10th) {
                              this.totalAmountRent = parseFloat(checkMonth1)+parseFloat(this.total_arresrs)
                        //    } else {
                        //       this.fine = parseFloat(this.shopList[0].monthly_shop_rent * 0.1);
                        //       this.totalAmountRent = parseFloat(checkMonth1) + this.fine+parseFloat(this.total_arresrs)
                        //    }
         //  window.sessionStorage.setItem('shopID', JSON.stringify(this.incomeObj.selectedShops))
         //  window.sessionStorage.setItem('shopID', JSON.stringify(shopid))
         this.shopdid = shopid
       },

       resetCheckErr: function () {
           this.errObj.nicErr = [];
           this.errObj.nameErr = [];
           this.errObj.phoneErr = [];
           this.errObj.addErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
            // NIC validate

            if (!this.cusObj.nic) {
                this.errors.push("Entering a nic is required");
            }
            else {
                // if (this.cusObj.nic.length !==12)
                //     {
                //         if((this.cusObj.nic.charAt(9).toUpperCase() !== 'V') || (this.cusObj.nic.charAt(9).toLowerCase() !== 'v'))
                //           {
                //             this.errors.push('NIC must be valid');
                //           }
                          
                //     }
                if ( this.cusObj.nic.length !== 12 &&
                            !(
                                this.cusObj.nic.charAt(9).toUpperCase() === 'V' ||
                                this.cusObj.nic.charAt(9).toLowerCase() === 'v' ||
                                this.cusObj.nic.charAt(9).toUpperCase() === 'X' ||
                                this.cusObj.nic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errors.push('NIC must be valid');
                        }
            
            }


           // Name validate
           if (!this.cusObj.name) {
               this.errObj.nameErr.push("Entering a name is required");
           }
          // else {
              // if (!/^[A-Za-z]+$/.test(this.cusObj.name.replace(/\s/g, ""))) {
              //     this.errObj.nameErr.push('A name can only contain letters');
              // }
          // }
          
           // Phone validate
           if (!this.cusObj.phone) {
               this.errObj.phoneErr.push('Entering phone number is required');
           }
           else{
                if(!/[0-9]{10}/.test(this.cusObj.phone)) {
                this.errObj.phoneErr.push('Phone numbers should contain 10 Digits, no spaces allowed');
                }
            }
  
             // Address Validate
             if (!this.cusObj.address) {
               this.errObj.addErr.push("Entering an address is required");
           }   

       },
      

       async handleSubmit1(e) {
           this.checkForm();

           if (!this.checkEmptyErr()) {
               e.preventDefault();
           } else {
               e.preventDefault();
               this.Nic12 = this.addZeroPadding();
               await this.getMatchCus(this.Nic12,this.sabha);
               if (this.matchCus) {
                   this.$refs.alert.showAlert('error', 'Something Went Wrong')

               }
               else {
                   let data = {
                     sabha_code : this.sabha,
                       cus_nic: this.Nic12,
                       cus_name: this.cusObj.name,
                       cus_contact: this.cusObj.phone,
                       cus_address: this.cusObj.address,

                       
                   }
         //           let sabhaCustomerData = {
                      
         //               cus_nic : this.cusObj.nic,

         //   };
                    await axios.post("/customers/", data)
                    this.$refs.alert.showAlert('success', 'User Added Successfully !')
                  
                  //  await axios.post('/sbha_cutomers/' , sabhaCustomerData);
                  
                   // document.getElementById("userForm").reset();
                 
                 
               }
           }
       },
       // second Form handle

       resetCheckErr2: function () {
           this.errIncomeObj.iheadErr = [];
           this.errIncomeObj.iDesErr = [];
           this.errIncomeObj.amountErr =[];
           this.errIncomeObj.stampErr = [];
           this.errIncomeObj.discErr = [];
           this.errIncomeObj.monyrErr=[];

       },
       checkEmptyErr2: function () {
           for (var typeErr in this.errIncomeObj) {
               if (this.errIncomeObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm2: function () {
           this.resetCheckErr2();
           // income head Validate
           if (!this.incomeObj.headid) {
               this.errIncomeObj.iheadErr.push("Income head is required");
           }
           if (!this.incomeObj.des) {
               this.errIncomeObj.iDesErr.push("Description is required");
           }
           if (!this.incomeObj.amount) {
               this.errIncomeObj.amountErr.push("Amount is required");
           }
           if (!this.incomeObj.stamp) {
               this.errIncomeObj.stampErr.push("stamp fee is required");
           }
           if (!this.incomeObj.discounts) {
               this.errIncomeObj.discErr.push("discount required");
           }
           if (!this.incomeObj.emonthyr) {
               this.errIncomeObj.monyrErr.push("Pay month is required");
           }
       },
    
   },
   components: {
       VueBasicAlert
   }

};

</script>

<style scoped>
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     text-align: center;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      padding: 2%;
  }
  th  {
     text-align: center;
  }
  .ttable, th, td {
  border: 1px solid;
  }
  
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}


hr {
   border-top: 3px solid #057835fa;
   width: 100%;
}





.menu-section {
   padding: 1rem 9%;
}

.menu-section .menu-tabs {
   /* margin-bottom: 30px; */
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
   background: #5e5c5cda;
   /* background-color: #27ae60; Income Topic */
}

.menu-section .menu-tabs .menu-tab-item {
   display: inline-block;
   cursor: pointer;
   padding: 5px 30px;
   border-radius: 30%;
   font-size: 20px;
   /* color: rgb(19, 18, 18); heading color */
   color: whitesmoke;
   font-weight: 500;
   text-transform: capitalize;
   transition: all 0.3s ease;
   margin: 0;
}

.menu-section .menu-tabs .menu-tab-item:hover {
  
   /* background-color: #f38609 !important; */
}

.menu-section .menu-tabs .menu-tab-item p {
   padding: none;
   margin: none;
}

.menu-section .box-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
   gap: 1.5rem;
   
}

.menu-section .box-container .box {
   border-radius: 0.5rem;
   position: relative;
   background: #f7f7f7;
   padding: 2rem;
   text-align: center;
   
}

.menu-section .box-container .box .fa-heart {
   position: absolute;
   top: 1.5rem;
   right: 1.5rem;
   font-size: 2.5rem;
   color: #666;
   cursor: pointer;
}

.menu-section .box-container .box .fa-heart:hover {
   color: #27ae60;
}

.menu-section .box-container .box .image {
   margin: 1rem 0;
}

.menu-section .box-container .box .image img {
   height: 15rem;
}

.menu-section .box-container .box .content h3 {
   font-size: 2rem;
   color: #130f40;
}

.menu-section .box-container .box .content .stars {
   padding: 1rem 0;
   font-size: 1.7rem;
}

.menu-section .box-container .box .content .stars i {
   color: gold;
}

.menu-section .box-container .box .content .stars span {
   color: #666;
}

.menu-section .box-container .box .content .desc p {
   font-size: 14px;
   margin: 0;
}

.menu-section .box-container .box .content .price {
   font-size: 2rem;
   color: #130f40;
}

.menu-section .box-container .box .content .price span {
   font-size: 1.5rem;
   color: #666;
   /* color: #911580; */
   text-decoration: line-through;
   
}

.menu-section .action-row {
   padding-top: 30px;
   width: 100%;
   text-align: center;
   font-size: 20px;
}

.menu-section .action-row .action-btn {
   background-color: #27ae60;
   padding: 3px;
   border: 2px solid #27ae60;
   border-radius: 30%;
   color: white;
}

.menu-section .action-row span {
   margin-right: 15px;
}

.menu-section .action-row span:hover {
   cursor: pointer;
}

.menu-section .action-row span.highlight {
   color: #f38609;
   
}

.menu-section .action-row span:first-of-type {
   margin-left: 15px;
}

.filter-drop-down {
   display: none;
}

@media (min-width: 576px) {

   .filter-heading,
   .filter-section {
       display: block !important;
   }
}

@media (max-width: 768px) {
   .menu-section .box-container {

       grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
       gap: 1rem;
        
   }

   .menu-section .box-container .box .content h3 {
       height: 4rem;
   }
}

@media (max-width: 576px) {

   .search-box,
   .filter-heading,
   .filter-section {
       width: auto;
   }

   .filter-option {
       width: 100%;
   }


   .menu-section .action-row {
       font-size: 16px !important;
   }

   .menu-section .action-row span {
       margin-right: 5px;
   }

   .menu-section .box-container .box .image img {
       height: 10rem;
   }

   .menu-section .box-container .box .desc p,
   .menu-section .box-container .box .content .stars {
       font-size: 10px !important;
   }

   .menu-section .box-container .box .content h3 {
       font-size: 14px !important;
       height: 28px;
   }
}
/* new */
.admin-container {
   background-color: #fff;
   /* height: 100vh; */
   padding: 2rem 9%;
   font-size: 16px;
}

.project-list>tbody>tr>td {
   padding: 12px 8px;
   font-size: small;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 20vh;
   /* height: 300px; */
    padding: 8px 90px;
}

.action-btn,


.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 
/* newly added from income heads */
.register-container {
   padding: 2rem 9%;
}

.register-container .register-form-container {
   background: #fff;
   /* width: 100% !important; */
}

.register-container .register-form-container form {
   /* position: relative; */
   /* left: 40%; */
   /* transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 1rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
  
} 
.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
   font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.register-container .register-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
   display: block; 
   overflow: hidden; 
   
}

.register-container .register-form-container form .btn {
   margin: 1rem 0;
   width: 10%;
   text-align: center;
   background-color : #032a2c;
   height: 30px;
   font-size: 1rem; 
}

.register-container .register-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}

.register-container .register-form-container form p a {
   color: #27ae60;
}

.register-container .register-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.register-container .register-form-container form .form-group {
   margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
.register-container .register-form-container form .container .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 0;
}
/* shroff form container */
.shroff-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 2rem 9%;
   font-size: 16px;
}

.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
   height: 500px;
}

.action-btn,
.cancel-btn,


.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}
.order-section form .row .input-box {
   width: 49%;
   padding: 1.8rem 0;
} 
/* newly added from income heads */
.shroff-container {
   padding: 2rem 9%;
}
/* ongoing */
.shroff-container .shroff-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.shroff-container .shroff-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
}

.shroff-container .shroff-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.shroff-container .shroff-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.shroff-container .shroff-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.shroff-container .shroff-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.shroff-container .shroff-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
   color:#f7f7f7;
    height: 3rem;
  
   
}
.shroff-container .shroff-form-container form .bbtn {
   /* margin: 1rem 0; */
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
   color: #f7f7f7;
    height: 3rem;
}
.shroff-container .shroff-form-container form .table {
   /* margin: 1rem 0; */
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    /* height: 3rem; */
   
}
.shroff-container .shroff-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.shroff-container .shroff-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: #666;
   margin: 0;
}

.shroff-container .shroff-form-container form p a {
   color: #27ae60;
}

.shroff-container .shroff-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.shroff-container .shroff-form-container form .form-group {
   margin: 0;
}

.shroff-container .shroff-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
.project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
}

.table-responsive {
   margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
   width: 100px;
   height: 25px;
   color: white;
   text-transform: capitalize;
}

.action-btn {
   background-color: #0da9ef;
   margin-right: 10px;
}

.cancel-btn,
.paid-btn {
   background-color: red;
}

.action-btn:hover {
   background-color: #27ae60;
}

</style>
